<template>
  <div>
    <div style="border: none">
      <div class="container">
        <div class="title">忘记密码</div>
        <div class="line"></div>
        <el-form
          :model="resetForm"
          :rules="resetRules"
          ref="resetFormRef"
          class="demo-resetForm"
        >
          <el-form-item prop="phone">
            <img class="img_l" src="@/assets/img/reset/username.png" alt=""/>
            <el-input
              v-model="resetForm.phone"
              placeholder="请输入手机号"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="email">
            <img class="img_l" src="@/assets/img/reset/username.png" alt="" />
            <el-input
              v-model="resetForm.email"
              placeholder="请输入邮箱"

            ></el-input>
          </el-form-item> -->
          <el-form-item prop="code">
            <img class="img_l" src="@/assets/img/reset/code.png" alt=""/>
            <el-input
              v-model="resetForm.code"
              placeholder="请输入短信验证码"
              maxlength="20"
            ></el-input>
            <!-- 短信验证码 -->
            <!-- <div class="phoneCode">
              <span class="code" v-show="showCode" @click="getCode"
                >获取验证码</span
              >
              <span v-show="!showCode" class="count">{{ count }} s</span>
            </div>
          </el-form-item> -->
            <!-- <el-form-item prop="code">
            <img class="img_l" src="@/assets/img/reset/code.png" alt="" />
            <el-input
              v-model="resetForm.code"
              placeholder="请输入邮箱验证码"
              maxlength="6"

            ></el-input> -->
            <!-- 短信验证码 -->
            <div class="phoneCode">
              <span class="code" v-show="showCode" @click="getCode"
              >获取验证码</span
              >
              <span v-show="!showCode" class="count">{{ count }} s</span>
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <img class="img_l" src="@/assets/img/reset/password.png" alt=""/>
            <el-input
              v-model="resetForm.password"
              maxlength="20"
              placeholder="设置密码：6-12位字符，包含字母或数字"
              :autocomplete="isShowPwd ? 'new-password' : 'off'"
            ></el-input>
            <!-- <img
              @click="changePwdStatus()"
              v-if="!isShowPwd"
              class="img_r"
              src="@/assets/img/reset/eye01.png"
              alt=""
            />
            <img
              @click="changePwdStatus()"
              v-if="isShowPwd"
              class="img_r"
              src="@/assets/img/reset/eye01.png"
              alt=""
            /> -->
          </el-form-item>
          <el-form-item prop="rq_password">
            <img class="img_l" src="@/assets/img/reset/password.png" alt=""/>
            <el-input
              v-model="resetForm.rq_password"
              placeholder="请再次输入新的登录密码"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin: 40px 0 0 0"></el-form-item>
          <el-form-item>
            <el-button @click="submitForm()"> 重置密码</el-button>
          </el-form-item>
          <div class="box">
            <div class="fr" @click="toLogin()">已有账号？马上登录</div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  // 请求接口
  import {passwordCode} from "r/index/register";
  import {changePassCode} from "r/index/login";
  import {mobileCheck} from "@/common/utils.js";
  import {intervalTime} from "store/time";
  import validator from "common/validator";

  export default {
    name: "index-reset",
    data() {
      const pwdCheck = async (rule, value, callback) => {
        if (value !== this.resetForm.password) {
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      };
      return {
        // 手机验证短信
        showCode: true,
        count: "",
        timer: null,
        codeTime: "", //手机验证码倒计时时间
        isShowPwd: true, // 控制密码显示隐藏
        resetForm: {
          phone: "",
          // email: "",
          code: "",
          password: "",
          rq_password: "",
        },
        // 表单验证规则对象
        resetRules: {
          // 对手机号进行校验
          phone: [
            {required: true, message: "请输入手机号", trigger: "blur"},
            {
              validator: function (rule, value, callback) {
                if (mobileCheck(value) === false) {
                  callback(new Error("请输入正确的手机号"));
                } else {
                  callback();
                }
              },
              trigger: "blur",
            },
          ],
          // 对邮箱验证
          // email: [
          //   { required: true, message: "请输入邮箱", trigger: "blur" },
          //   {
          //     validator: validator.validateEmail,
          //     trigger: "blur",
          //   },
          // ],
          // 对验证码进行校验
          code: [
            {required: true, message: "请输入验证码", trigger: "blur"},
            {min: 6, max: 20, message: "请输入正确的验证码", trigger: "blur"},
          ],
          // 对密码进行校验
          password: [
            {
              required: true,
              message: "请输入密码",
              transform: (value) => value,
              trigger: "blur",
            },
            {
              type: "string",
              message: "请输入不包含空格的字符",
              trigger: "blur",
              transform(value) {
                if (value && value.indexOf(" ") === -1) {
                  return value;
                } else {
                  return false;
                }
              },
            },
            {
              trigger: "blur",
              validator: (rule, value, callback) => {
                var passwordreg = /^[a-zA-Z0-9]{6,12}$/;
                // /(?=.*\d)(?=.*[a-zA-Z]).{6,12}/;
                if (!passwordreg.test(value)) {
                  callback(new Error("请输入6-12位，包含数字/字母,"));
                } else {
                  callback();
                }
              },
            },
          ],
          // 对密码进行校验
          rq_password: [
            {required: true, message: "请输入确认密码", trigger: "blur"},
            {
              validator: pwdCheck,
              trigger: "blur",
            },
          ],
        },
      };
    },
    created() {
      const endTime = window.localStorage.getItem("reset_code_time");
      const phone = window.localStorage.getItem("reset_code_phone");
      if (endTime > 0) {
        this.resetForm.phone = phone;
        this.intervalHandleNew(endTime);
      }
    },
    methods: {
      // 去注册
      toLogin() {
        let code = this.$store.state.indexIdentity;
        this.$router.push({
          path: "/login",
          query: {
            ...this.$store.state.query,
            code: code
          }
        });
      },
      // 改变密码显示状态
      changePwdStatus() {
        this.isShowPwd = !this.isShowPwd;
      },
      // 定时器倒计时
      intervalHandleNew(timeLag) {
        if (!this.timer) {
          this.count = timeLag - 1;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= timeLag) {
              this.count--;
              window.localStorage.setItem("reset_code_time", this.count);
            } else {
              clearInterval(this.timer);
              this.showCode = true;
              this.timer = null;
            }
          }, 1000);
        }
      },
      // 定时器倒计时
      intervalHandle(startTime, endTime) {
        // 时间差
        const timeLag = intervalTime(startTime, endTime);
        console.log(timeLag);
        if (!this.timer) {
          this.count = timeLag - 1;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= timeLag) {
              this.count--;
            } else {
              this.showCode = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      },
      // 获取手机验证短信
      getCode() {
        if (!this.resetForm.phone) {
          return this.$message.error("请输入手机号");
        }
        // 接口获得验证码
        passwordCode({
          phone: this.resetForm.phone,
          // email: this.resetForm.email,
        }).then((res) => {
          console.log(res, "passwordCode");
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);

          this.codeTime = res.data.expire_time;
          window.localStorage.setItem("reset_code_time", res.data.expire_time);
          window.localStorage.setItem("reset_code_phone", this.resetForm.phone);
          this.intervalHandleNew(res.data.expire_time);
        });
      },
      // 表单验证
      submitForm() {
        this.$refs["resetFormRef"].validate((valid) => {
          if (!valid) return;
          changePassCode({
            phone: this.resetForm.phone,
            email: this.resetForm.email,
            code: this.resetForm.code,
            password: this.resetForm.password,
            rq_password: this.resetForm.rq_password,
          }).then((res) => {
            console.log(res);
            if (res.data.code != 200) {
              return this.$message.error(res.data.msg);
            }
            this.$message.success(res.data.msg);
            // token存储
            let code = this.$store.state.indexIdentity;
            window.localStorage.setItem("login_code_phone", this.resetForm.phone);
            // 跳转到登录
            this.$router.replace({
              path: "/login",
              query: {
                ...this.$store.state.query,
                code: code
              }
            });
          });
        });
      },
    },
    destroyed() {
      this.timer && clearInterval(this.timer);
      localStorage.removeItem('reset_code_time');
      localStorage.removeItem('reset_code_phone');
    }
  };
</script>

<style scoped lang="scss">
  // 注册输入
  .container {
    background: #ffffff;
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 40px 60px;
    margin-top: 0;
    overflow: hidden;

    .title {
      // margin: 19px 0 6px 0;
      // width: 132px;
      height: 20px;
      font-size: 22px;
      font-weight: 500;
      color: var(--color);
      line-height: 20px;
      text-align: center;
    }

    // .line {
    //   width: 83px;
    //   height: 3px;
    //   background: var(--color);
    //   border-radius: 2px;
    // }

    ::v-deep .el-form {
      margin-top: 60px;

      .el-form-item {
        position: relative;
        margin-bottom: 30px;

        &:last-child {
          margin: 0;
        }

        .el-form-item__content {
          position: relative;

          .phoneCode {
            position: absolute;
            z-index: 999;
            top: 50%;
            right: 25px;
            transform: translate(0, -50%);
            cursor: pointer;

            .code {
              font-size: 12px;
              font-weight: 600;
              color: var(--color);
            }
          }

          img.img_l {
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1000;
          }

          img.img_r {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translate(0, -50%);
            z-index: 1000;
          }

          .el-input__inner {
            height: 50px;
            line-height: 50px;
            padding: 0 10px 0 50px;
            border-radius: 0;
            color: #4d4d4d;
            border: 0;
            border-bottom: 2px solid #d9d9d9;

            &:focus {
              border-bottom-color: var(--color);
            }
          }

          .el-form-item__error {
            color: #e0823d;
          }

          .el-button {
            width: 100%;
            height: 60px;
            background: var(--color);
            border-radius: 4px;
            opacity: 0.8;
            line-height: 50px;
            text-align: center;
            color: #fff;
            font-size: 16px;
            padding: 0;
            margin: 30px 0 0 0;
          }
        }
      }

      .box {
        font-size: 12px;
        height: 18px;
        display: block;

        .fr {
          // float: left;
          text-align: center;
          height: 18px;
          line-height: 18px;
          color: #999999;
          margin-top: -10px;
          cursor: pointer;

          &:hover {
            color: var(--color);
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1601px) {
    .container {
      right: 18%;
    }
  }

  @media screen and (max-width: 1501px) {
    .container {
      right: 16%;
    }
  }

  @media screen and (max-width: 1401px) {
    .container {
      right: 14%;
    }
  }
</style>
